export const LIFESTYLE_CONFIG = {
    "Tall Family Members": {
        "frontRowLegRoomInches":{
            text: "Front Leg Room",
            type: "value",
            unit: "\""
        },
        "frontHeadRoomInches":{
            text: "Front Head Room",
            type: "value",
            unit: "\""
        }
    },
    "Large Dog": {
        "interiorRearCargoVolumeCubicFeet":{
            text: "Interior Rear Cargo Volume",
            type: "value",
            unit: "ft³"
        },
        "interiorRearCargoVolumeWithSeatsFoldedCubicFeet": {
            text: "Interior Rear Cargo Volume with Seats Folded",
            type: "value",
            unit: "ft³"
        },
        "maxInteriorRearCargoVolumeCubicFeet":{
            text: "Interior Maximum Rear Cargo Volume",
            type: "value",
            unit: "ft³"
        },
    },
    "Multiple Main Drivers": {
        "seatMemory":{
            text: "Memory Seat",
            type: "boolean"
        },
        "powerSeats":{
            text: "Power Seats",
            type: "boolean"
        }
    },
    "1-2 Kids": {
        "rearLegRoomInches":{
            text:"Rear Legroom",
            type: "value",
            unit: "\""
        }, 
        "heatedRearSeats":{
            text: "Heated Rear Seats",
            type: "boolean"
        }
    }, 
    "3-4 Kids": {
        "seatingCapacity": {
            text: "Seating Capacity",
            type: "value",
        },
        "thirdRowLegRoomInches":{
            text: "Third Row Leg Room",
            type: "value",
            unit: "\""
        }
    },
    "5+ Kids": {
        "seatingCapacity": {
            text: "Seating Capacity",
            type: "value",
        },
        "thirdRowLegRoomInches":{
            text: "Third Row Leg Room",
            type: "value",
            unit: "\""
        }
    },
    "Older Family Members": {
        "groundClearanceInches":{
            text: "Ground Clearance",
            type: "value",
            unit: "\""
        }
    },
    "New Driver": {
        "blindSpotSensor":{
            text: "Blind Spot Sensor",
            type: "boolean"
        },
        "automaticBrakeHold":{
            text: "Automatic Brake Hold",
            type: "boolean"
        },
        "rearCollisionAlert":{
            text: "Rear Collision Alert",
            type: "boolean"
        },
        "fontAndRearParkingSensors":{
            text: "Front and Rear Parking Sensors",
            type: "boolean"
        }
    },
    "Car Seat(s)": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "childDoorLock":{
            text:"Child Door Lock",
            type:"boolean"
        }
    },
    "Golfing": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        }
    },
    "Skiing/Snowboarding": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "roofRails":{
            text:"Roof Rails",
            type:"boolean"
        }
    },
    "Biking": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "roofRails":{
            text:"Roof Rails",
            type:"boolean"
        }
    },
    "Camping": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "powerOutlet12VDC":{
            text:"12V DC Power Outlets",
            type:"boolean"
        },
        "powerOutlet120VAC":{
            text:"120V AC Power Outlets",
            type:"boolean"
        }
    },
    "Canoe/Kayak": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        }
    },
    "Sports Practices": {
        "powerLiftgate":{
            text:"Power Liftgate",
            type:"boolean"
        },
        "interiorRearCargoVolumeCubicFeet":{
            text: "Interior Rear Cargo Vol",
            type: "value",
            unit: "ft³"
        },
    },
    "Trailer": {
        "towingCapacityLbs":{
            text:"Towing Capacity",
            type:"value",
            unit: "lb"
        }
    },
    "Boat": {
        "towingCapacityLbs":{
            text:"Towing Capacity",
            type:"value",
            unit: "lb"
        }
    },
    "Camper": {
        "towingCapacityLbs":{
            text:"Towing Capacity",
            type:"value",
            unit: "lb"
        }
    },
    "Off Roading": {
        "driveTrain": {
            text: "Drive Train",
            type: "value",
            location: "not in maefeatures"
        },
        "groundClearanceInches":{
            text: "Ground Clearance",
            type: "value",
            unit: "\""
        },
        "heatedSteeringWheel": {
            text:"Heated Steering Wheel",
            type:"boolean"
        },
        "heatedSeats": {
            text:"Heated Front Seats",
            type:"boolean"
        }
    },
    "Sporty Driving": {
        "torqueLbFt": {
            text: "Torque",
            type: "value",
            unit: "lb-ft"
        },
        "horsepower": {
            text: "Horsepower",
            type: "value",
            unit: "hp"
        }
    },
    "Lots of City/Traffic": {
        "stopAndGo": {
            text: "Stop and Go",
            type: "boolean"
        }
    },
    "Lots of Highway Driving": {
        "adaptiveCruiseControl": {
            text: "Adaptive Cruise Control",
            type: "boolean"
        },
        "laneDepartureWarning": {
            text: "Lane Departure Warning",
            type: "boolean"
        },
        "autoHighBeamHeadlights": {
            text: "Auto High Beem Headlights",
            type: "boolean"
        },
        "overallLengthInches": {
            text: "Overall Length (mm)",
            type: "value",
            unit: "\""
        }
    },
    "Snow": {
        "driveTrain": {
            text: "Drive Train",
            type: "value",
            location: "not in maefeatures"
        }
    }

}