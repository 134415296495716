export function getRandomColor() {
    const hue = Math.floor(Math.random() * 21) + 30;
    const saturation = Math.floor(Math.random() * 30) + 70; 
    const lightness = Math.floor(Math.random() * 30) + 40;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`; 
}

export function groupInventory( carMatches ) {

    const groupedCarMatchesObj = {}; 

    try {
        carMatches.forEach((carInfo , _) => {
            const maeIdKey = carInfo.maeId.split('~').slice(0, -1).join('~');
            if(maeIdKey in groupedCarMatchesObj) {
                groupedCarMatchesObj[maeIdKey].carList.push(carInfo);
            } else {
                groupedCarMatchesObj[maeIdKey] = { expand : false , carList : [carInfo], faceCar: carInfo, color: getRandomColor() };
            }
        })
    } catch(error) {
        console.log("ERROR:", error);
    }
    
    console.log(groupedCarMatchesObj , "groupedCarMatchesObj");
    return groupedCarMatchesObj;
}