import "./DashboardQuiz.scss";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { produce } from "immer";


/* Components needed for the Quiz */
import QuizProgressBar from "../QuizProgressBar/QuizProgressBar";
import { QuizOptionCardContainer } from "../QuizOptionCard/QuizOptionCard";
import ButtonPlain from "../ButtonPlain/ButtonPlain";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import QuizFact from "../QuizFact/QuizFact";

import { useDealershipContext } from "../../contexts/dealershipContext";
import { createDealershipUser } from "../../services/dealership-user";

/* SVG needed in the dashboard quiz */
import { ReactComponent as MaeCoreLogoYellow } from "../../assets/logos/Mae-Minimized-Logo-Yellow.svg";

import {
  BUDGET_QUIZ_INTERFACE,
  CUSTOMER_FORM_QUIZ_INTERFACE,
  MAKE_MODEL_SELECTION_QUIZ_INTERFACE,
  RANKING_QUIZ_INTERFACE,
} from "../../constants/quizInterfaces";
import QISelectMakeModel from "../QISelectMakeModel/QISelectMakeModel";
import QIBudgetSelector from "../QIBudgetSelector/QIBudgetSelector";
import QICustomerDetailsForm from "../QICustomerDetailsForm/QICustomerDetailsForm";
import QIRanking from "../QIRanking/QIRanking";
import React from "react";
import {quizQuestionsFactory} from "../../data/dealershipQuiz/quizQuestionsFactory"

export function QuestionInterfaceSelector({
  quizQuestions,
  setQuizQuestions,
  currentQuestionViewIndex,
  childIndex,
  dealershipId
}) {
  const { quizInterface } =
    quizQuestions[currentQuestionViewIndex].children[childIndex];
  switch (quizInterface) {
    case BUDGET_QUIZ_INTERFACE:
      return (
        <QIBudgetSelector
          currentQuestionViewIndex={currentQuestionViewIndex}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          childIndex={childIndex}
        />
      );

    case MAKE_MODEL_SELECTION_QUIZ_INTERFACE:
      return (
        <QISelectMakeModel
          currentQuestionViewIndex={currentQuestionViewIndex}
          setQuizQuestions={setQuizQuestions}
          quizQuestions={quizQuestions}
          childIndex={childIndex}
        />
      );

    case CUSTOMER_FORM_QUIZ_INTERFACE:
      return (
        <QICustomerDetailsForm
          currentQuestionViewIndex={currentQuestionViewIndex}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          childIndex={childIndex}
          dealershipId={dealershipId}
        />
      );

    case RANKING_QUIZ_INTERFACE:
      return (
        <QIRanking
          currentQuestionViewIndex={currentQuestionViewIndex}
          childIndex={childIndex}
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
        />
      );

    default:
      return (
        <QuizOptionCardContainer
          quizQuestions={quizQuestions}
          setQuizQuestions={setQuizQuestions}
          currentQuestionViewIndex={currentQuestionViewIndex}
          childIndex={childIndex}
        />
      );
  }
}

function DashboardQuiz() {
  const {
    dealershipId,
    userSource,
    clearDealershipUserSource,
    salesPersonInfo,
    clearSalesPersonInfo,
  } = useDealershipContext();
  const [minimumSelected, setMinimumSelected] = useState(false);
  const [quizQuestions, setQuizQuestions] = useState(quizQuestionsFactory(dealershipId));
  const quizComponentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  const count = quizQuestions.length;

  const [currentQuestionViewIndex, setCurrentQuestionViewIndex] = useState(0);
  const [history, setHistory] = useState([]);

  const countSelected = () => {
    const currentQuestionView = quizQuestions[currentQuestionViewIndex];
    const questionViewAnswered = currentQuestionView.isAnswered.call(quizQuestions, currentQuestionViewIndex)
    setMinimumSelected(questionViewAnswered);
    if(!currentQuestionView.visitedOnce && currentQuestionView.automaticForward && questionViewAnswered) {
      nextQuestion();
    }
  };

  useEffect(() => {
    countSelected();
  }, [quizQuestions, currentQuestionViewIndex]);

  const nextQuestion = () => {
    console.log("next question");
    setQuizQuestions(
      produce((quizQuestionsDraft) => {
        quizQuestionsDraft[currentQuestionViewIndex].visitedOnce = true;
      })
    );
    console.log(currentQuestionViewIndex);
    if (currentQuestionViewIndex + 1 < count) {
      for (
        let nextQuestionIndex = currentQuestionViewIndex + 1;
        nextQuestionIndex < count;
        nextQuestionIndex++
      ) {
        // console.log(nextQuestionIndex);
        // console.log(quizQuestions[nextQuestionIndex]);
        if (quizQuestions[nextQuestionIndex].visibilityCondition.call(quizQuestions) === true) {
          setHistory((prev) => [...prev, currentQuestionViewIndex]);
          setCurrentQuestionViewIndex(nextQuestionIndex);
          return;
        }
      }
    } else {
      submit();
    }
  };

  const prevQuestion = () => {
    if (history.length > 0) {
      setHistory((prev) => {
        const updatedHistory = [...prev];
        const lastIndex = updatedHistory.pop(); // Remove last question
        setCurrentQuestionViewIndex(lastIndex); // Go to the previous question
        return updatedHistory;
      });
    } else {
      navigate(`/${dealershipId}`);
    }
  };

  const submit = async () => {

    const quizSelections = [];
    console.log("entering quiz selections");
    for (let i = 0; i < count; i++) {
      const responses = quizQuestions[i].responses.call(quizQuestions[i]);
      if(responses.length && responses.length > 0) {
        responses.forEach(responseObj => {
          quizSelections.push(responseObj);
        });
      } else {
        quizSelections.push(responses);
      }
    }
    console.log(quizSelections, "quizSelections");

    console.log("sales personn", salesPersonInfo)

    const response = await createDealershipUser(
      quizSelections,
      dealershipId,
      userSource,
      salesPersonInfo.name, // sale person name
      salesPersonInfo.email //sale person email
    );

    clearDealershipUserSource();
    clearSalesPersonInfo();

    console.log(response, "response");
    navigate(`/${dealershipId}/matches?id=${response.userId}&email=0`);
  };

  useEffect(() => {
    quizComponentRef.current.scrollTop = 0;
  }, [currentQuestionViewIndex]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="quiz" ref={quizComponentRef}>
      <div className="quiz__filler">
        <div className="quiz__intro">
          <div className="quiz__intro--titles">
            <h2 className="header">Create Your Profile</h2>
            <QuizProgressBar count={count} active={currentQuestionViewIndex} />
            <h1 className="subheader">
              {quizQuestions[currentQuestionViewIndex].viewText}
            </h1>
            <h3 className="subtext">
              {quizQuestions[currentQuestionViewIndex].viewSubtext}
            </h3>
          </div>
        </div>
        <div className={quizQuestions[currentQuestionViewIndex].quizFact? "question_divider_row": "question_divider_column"}>
         <div className = "combined-question-container">
            {quizQuestions[currentQuestionViewIndex].children.map((questionObj, childIndex) => {
                return (
                <div key={childIndex} className = "combined-question-subcontainer">
                    <p className = "combined-option-title">{questionObj.text}</p>
                    <div  className={questionObj.optionCardsClassname}>
                      <QuestionInterfaceSelector
                        quizQuestions={quizQuestions}
                        setQuizQuestions={setQuizQuestions}
                        currentQuestionViewIndex={currentQuestionViewIndex}
                        childIndex={childIndex}
                        dealershipId={dealershipId}
                      />
                    </div>
                </div>)
            })}
         </div>
          {quizQuestions[currentQuestionViewIndex].quizFact && (
            <QuizFact
              headText={
                quizQuestions[currentQuestionViewIndex].quizFact?.headText
              }
              infoText={
                quizQuestions[currentQuestionViewIndex].quizFact?.infoText
              }
              infoImg={quizQuestions[currentQuestionViewIndex].quizFact?.img}
            />
          )}
        </div>
        <div className="quiz__footer">
          <div className="quiz__buttons">
            <div>
              <ButtonPlain
                border="none"
                padding="1.25rem 2.5rem"
                text="Back"
                onClick={prevQuestion}
              />
            </div>
            <div>
              {
                <ButtonPlain
                  color={minimumSelected ? "#FFE6A9" : "#EEEEED"}
                  border="none"
                  padding="1.25rem 4.125rem"
                  text={
                    currentQuestionViewIndex === count - 1
                      ? "Complete Check-In"
                      : "Continue"
                  }
                  onClick={minimumSelected ? nextQuestion : null}
                />
              }
            </div>
          </div>
          <div className="badge__logo">
            <div style={{ fontWeight: "400", marginRight: "0.75rem" }}>
              Powered By{" "}
            </div>
            <MaeCoreLogoYellow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardQuiz;
