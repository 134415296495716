export function isAnsweredWithInput(currentQuestionViewIndex, childIndex) {
    const currentQuestion = this[currentQuestionViewIndex].children[childIndex];
    if (currentQuestion?.inputCondition?.call(this) === true) {
        return currentQuestion.inputValue?.length > 0;
    }
    return currentQuestion.currSelected.length >= currentQuestion.selectionRange.minimumSelection;
}

export function minimumOptionsSelected(currentQuestionViewIndex, childIndex) {
    const currentQuestion = this[currentQuestionViewIndex].children[childIndex];
    return currentQuestion.currSelected.length >= currentQuestion.selectionRange.minimumSelection;
}

export function isAnsweredWithBudget(currentQuestionViewIndex, childIndex) {
    const currentQuestion = this[currentQuestionViewIndex].children[childIndex];
    return (currentQuestion.minBudget.length > 0 && currentQuestion.maxBudget.length > 0) || currentQuestion.monthlyBudget.length > 0;
}

export function isAnsweredWithUserInfo(currentQuestionViewIndex, childIndex) {
    const currentQuestion = this[currentQuestionViewIndex].children[childIndex];
    if (currentQuestion.hasPostalCode) {
        return currentQuestion.postalCode && currentQuestion.firstName && currentQuestion.lastName && currentQuestion.phoneNo && currentQuestion.preferredMethodOfContact;
    }
    return currentQuestion.firstName && currentQuestion.lastName && currentQuestion.phoneNo && currentQuestion.preferredMethodOfContact;
}

export function isAnsweredWithMakeModel(currentQuestionViewIndex, childIndex) {
    return true //is optional right now
    const currentQuestion = this[currentQuestionViewIndex].children[childIndex];
    return currentQuestion.currSelected[0].make.length >= 0 && currentQuestion.currSelected[0].model.length >= 0;
}
